/** scrollbar global styles for all skins */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}
/******************************************/
.app-logo {
  height: var(--logo-height);
  @media (max-width: 1000px) {
    height: var(--logo-min-height);
  }
  cursor: pointer;
}

.header-border {
  border-bottom: var(--border);
}
.header-background {
  background-color: var(--header-color);
  color: var(--text);
}
.header-user-info {
  color: var(--header-user-info);
}
.login-out-button {
  color: var(--text-color-light) !important;
  background-color: var(--primary-color) !important;
  &:hover {
    background-color: var(--primary-color) !important;
  }
}
/******************************************/
.footer-border {
  border-top: var(--border);
}
.footer-background {
  background-color: var(--footer-color);
  color: var(--text);
}
.footer-icon > div {
  color: var(--primary-color);
}
/******************************************/
.table-container {
  max-height: calc(
    100vh -
      (
        #{var(--header-height-desktop)} + #{var(--footer-height-auth)} + #{var(
            --menu-sections-height
          )} + #{var(--table-app-bar)} + 13px
      )
  );
  @media (max-width: 1350px) {
    max-height: calc(
      100vh -
        (#{var(--header-height-desktop)} + #{var(--footer-height-auth)} + 35px)
    );
  }
}
.table-header {
  & > th {
    background-color: var(--primary-color);
    color: var(--text-color-light);
  }
}
.table-appBar .create-user-button {
  background-color: var(--primary-color);
  &:hover {
    background-color: var(--primary-color);
  }
}

/******************************************/
.info-box {
  & > div {
    color: var(--primary-color);
  }
  & > span {
    color: var(--text-color-dark);
  }
}
.loading-wrapper > span {
  background-color: var(--primary-color);
  color: var(--text-color-light) !important;
}
.navigation-icon {
  background-color: var(--primary-color) !important;
}
.reset-password-link {
  color: var(--primary-color) !important;
}
.login-button {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

//slider
.slick-slider .slick-dots {
  bottom: 25px;
}
.slick-slider .slick-next {
  right: 20px;
  z-index: 2;
}
.slick-slider .slick-prev {
  left: 10px;
  z-index: 2;
}
.slick-slider .slick-dots .slick-active ::before {
  color: white;
  font-size: 12px;
}
.slick-slider .slick-dots li button::before {
  color: white !important;
  font-size: 10px;
}
.slick-slider .slick-next:before {
  font-size: 30px;
}
.slick-slider .slick-prev:before {
  font-size: 30px;
}
